import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dropdown", "icon"];

  connect() {
    document.addEventListener("click", this.hide.bind(this));
  }

  disconnect() {
    document.removeEventListener("click", this.hide.bind(this));
  }

  toggle() {
    this.dropdownTarget.classList.toggle("hidden");
    this.toggleIconRotation();
  }

  hide(event) {
    if (!this.element.contains(event.target)) {
      this.dropdownTarget.classList.add("hidden");
      this.resetIconRotation();
    }
  }

  toggleIconRotation() {
    if (this.hasIconTarget) {
      this.iconTarget.classList.toggle("rotate-180");
    }
  }

  resetIconRotation() {
    if (this.hasIconTarget) {
      this.iconTarget.classList.remove("rotate-180");
    }
  }
}
