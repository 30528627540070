import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["panel", "tab"];
  static classes = ["selected", "unselected"];

  connect() {
    const button = this.element.querySelector("button");
    button.click();
  }

  select(event) {
    const panelElm = document.getElementById(event.params.panel);
    const tabElm = document.getElementById(event.params.tab);

    this.tabTargets.forEach((item) => {
      if (item == tabElm) {
        item.classList.add(this.selectedClass);
        item.classList.remove(this.unselectedClass);
      } else {
        item.classList.remove(this.selectedClass);
        item.classList.add(this.unselectedClass);
      }
    });

    this.panelTargets.forEach((item) => {
      if (item == panelElm) {
        item.classList.remove("hidden");
      } else {
        item.classList.add("hidden");
      }
    });
  }
}
