import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["menu"];

  toggle() {
    const body = document.querySelector("body");

    if (body.classList.contains("mobile-menu-opened")) {
      body.classList.remove("mobile-menu-opened");
    } else {
      body.classList.add("mobile-menu-opened");
    }
  }
}
