import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["wrap", "form", "quantity", "showQuantity", "updatedQuantity", "input"];
  static values = {
    caseQuantity: Number,
  };

  toggleCustomInput() {
    this.inputTarget.classList.toggle("hidden");
    this.showQuantityTarget.classList.toggle("hidden");
  }

  showCustomInput() {
    this.toggleCustomInput();
    this.inputTarget.focus();
  }

  setQuantity(value) {
    this.quantityTarget.value = value;

    // Manually trigger the input event for analytics
    this.quantityTarget.dispatchEvent(new Event("input"));
  }

  setInputQuantity(value) {
    this.inputTarget.value = value;
  }

  setShowQuantity(value) {
    this.showQuantityTarget.innerHTML = value;
  }

  onCustomInputChange(e) {
    let validValue = e.target.value.replace(/[^0-9]/g, "");

    if (validValue === "0") {
      validValue = "";
    }

    e.target.value = validValue;

    this.setQuantity(validValue);
    this.setShowQuantity(validValue);
  }

  customInputBlurHandler() {
    if (this.inputTarget.value === "") {
      this.setQuantity(this.caseQuantityValue);
      this.setInputQuantity(this.caseQuantityValue);
    } else if (!this.inputTarget.checkValidity()) {
      const currentValue = this.inputTarget.value;
      const ceilRoundedValue = Math.ceil(currentValue / this.caseQuantityValue) * this.caseQuantityValue;
      const floorRoundedValue = Math.max(1, Math.floor(currentValue / this.caseQuantityValue)) * this.caseQuantityValue;

      const validValue =
        Math.abs(currentValue - ceilRoundedValue) < Math.abs(currentValue - floorRoundedValue)
          ? ceilRoundedValue
          : floorRoundedValue;

      this.setQuantity(validValue);
      this.setInputQuantity(validValue);
    }

    this.toggleCustomInput();
    this.submit();
  }

  forceCustomInputBlur(e) {
    this.inputTarget.blur();

    e.preventDefault();
    return false;
  }

  updatedQuantityTargetConnected() {
    const value = this.updatedQuantityTarget.getAttribute("data-value");

    if (value > 0) {
      this.wrapTarget.classList.add("active", "justify-center");
      this.setShowQuantity(value);
      this.setQuantity(value);
      this.setInputQuantity(value);
    } else {
      this.wrapTarget.classList.remove("active", "justify-center");
    }
  }

  submit() {
    this.formTarget.requestSubmit();
  }
}
