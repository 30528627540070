import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form"];

  connect() {
    this.formTarget.addEventListener("turbo:before-fetch-response", function (event) {
      if (typeof event.detail.fetchResponse !== "undefined") {
        let redirectLocation = event.detail.fetchResponse.response.headers.get("TURBO_REDIRECT_EXTERNAL");
        if (redirectLocation) {
          event.preventDefault();
          window.location.href = redirectLocation;
        }
      }
    });
  }

  navigate(event) {
    event.preventDefault();

    const href = event.target.getAttribute("href");
    const input = document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", "checkout[redirect_to]");
    input.setAttribute("value", href);
    this.formTarget.appendChild(input);

    this.formTarget.requestSubmit();
  }
}
