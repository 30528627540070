import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["shippingLine1", "postalCode", "country", "city"];

  connect() {
    if (typeof google !== "undefined" && google.maps && google.maps.places) {
      this.initAutocomplete();
    } else {
      window.initAutocomplete = this.initAutocomplete.bind(this);
    }
  }

  initAutocomplete() {
    const autocompleteInput = this.shippingLine1Target;
    if (!autocompleteInput) {
      console.error("Autocomplete input element not found.");
      return;
    }

    const autocomplete = new google.maps.places.Autocomplete(autocompleteInput, {
      componentRestrictions: {country: this.countryTarget.value},
      fields: ["address_components"],
      types: ["address"],
    });
    autocomplete.addListener("place_changed", () => this.fillInAddress(autocomplete));

    this.keyboardEvent(autocompleteInput);
  }

  fillInAddress(autocomplete) {
    const place = autocomplete.getPlace();
    if (!place || !place.address_components) {
      console.error("Autocomplete place details are not available.");
      return;
    }

    let streetNumber = "";
    let route = "";
    let postcode = "";
    let city = "";

    place.address_components.forEach((component) => {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number": {
          streetNumber = component.long_name;
          break;
        }
        case "route": {
          route = component.short_name;
          break;
        }
        case "postal_code":
          postcode = component.long_name;
          break;
        case "locality":
          city = component.long_name;
          break;
      }
    });

    this.shippingLine1Target.value = `${route} ${streetNumber}`;
    this.postalCodeTarget.value = postcode;
    this.cityTarget.value = city;
  }

  keyboardEvent(input) {
    input.addEventListener("keydown", (e) => {
      if (e.key === "Enter") {
        const googleDOMNodes = document.getElementsByClassName("pac-container");
        const googleDOMNodeIsVisible = Array.from(googleDOMNodes).some((node) => node.offsetParent !== null);
        if (googleDOMNodeIsVisible) {
          e.preventDefault();
          // Manually trigger the place_changed event to select the current address
          const firstPrediction = googleDOMNodes[0]?.querySelector(".pac-item");
          if (firstPrediction) {
            firstPrediction.click();
          }
        }
      }
    });
  }
}
