import {Controller} from "@hotwired/stimulus";
import {formatPrice} from "../utils";

export default class extends Controller {
  static targets = ["dropdown", "quantity", "input", "inputDisplay", "submitButton"];
  static values = {
    quantity: Number,
    currency: String,
    price: Number,
    caseQuantity: Number,
  };

  toggleDropdown() {
    this.dropdownTarget.classList.toggle("hidden");
  }

  toggleCustomInput() {
    this.inputDisplayTarget.classList.toggle("hidden");
    this.inputTarget.classList.toggle("hidden");
  }

  select(e) {
    this.inputDisplayTarget.innerText = e.target.innerText;
    this.setQuantity(e.params.quantity);

    this.toggleDropdown();
  }

  setQuantity(value) {
    this.quantityTarget.value = value;

    // Manually trigger the input event for analytics
    this.quantityTarget.dispatchEvent(new Event("input"));
  }

  showCustomInput() {
    this.toggleDropdown();
    this.toggleCustomInput();
    this.inputTarget.value = "";
    this.inputTarget.focus();
  }

  customInputHandler(e) {
    this.setQuantity(e.target.value);
  }

  customInputBlurHandler() {
    if (this.inputTarget.value === "") {
      this.setQuantity(this.caseQuantityValue);
      this.inputTarget.value = this.caseQuantityValue;
    } else if (!this.inputTarget.checkValidity()) {
      const currentValue = this.inputTarget.value;
      const ceilRoundedValue = Math.ceil(currentValue / this.caseQuantityValue) * this.caseQuantityValue;
      const floorRoundedValue = Math.max(1, Math.floor(currentValue / this.caseQuantityValue)) * this.caseQuantityValue;

      const validValue =
        Math.abs(currentValue - ceilRoundedValue) < Math.abs(currentValue - floorRoundedValue)
          ? ceilRoundedValue
          : floorRoundedValue;

      this.setQuantity(validValue);
      this.inputTarget.value = validValue;
    }

    this.inputDisplayTarget.innerText = `${this.quantityTarget.value} (${formatPrice(
      this.priceValue * this.quantityTarget.value,
      this.currencyValue,
    )})`;

    this.toggleCustomInput();
    this.submitButtonTarget.removeAttribute("disabled");
  }

  forceCustomInputBlur(e) {
    this.inputTarget.blur();

    e.preventDefault();
    return false;
  }

  close(e) {
    if (e.target.closest(".dropdown") === null) {
      this.dropdownTarget.classList.add("hidden");
    }
  }
}
