import {Controller} from "@hotwired/stimulus";
import {calculateOrderSummery, formatPrice} from "../utils.js";

export default class extends Controller {
  static targets = ["servicePoints", "manualCarrier", "bemakersCarrier"];

  static values = {
    isCollect: Boolean,
    isManual: Boolean,
    isBemakers: Boolean,
    shippingMethod: String,
  };

  connect() {
    this.servicePointsFrame = this.servicePointsTarget.querySelector("#service_points");
    this.toggle(this.isCollectValue, this.isManualValue, this.isBemakersValue, this.shippingMethodValue);
  }

  selected(event) {
    this.toggle(event.params.isCollect, event.params.isManual, event.params.isBemakers, event.target.value);
  }

  toggle(isCollect, isManual, isBemakers, shippingMethod) {
    if (isCollect) {
      this.resetServicePointsFrame(shippingMethod);
      this.servicePointsTarget.classList.remove("hidden");
    } else {
      this.resetServicePointsFrame();
      this.servicePointsTarget.classList.add("hidden");
    }

    if (isManual) {
      this.manualCarrierTarget.classList.remove("hidden");
    } else {
      this.manualCarrierTarget.classList.add("hidden");
    }

    if (isBemakers) {
      this.bemakersCarrierTarget.classList.remove("hidden");
    } else {
      this.bemakersCarrierTarget.classList.add("hidden");
    }

    this.recalcOrderSummary();
  }

  recalcOrderSummary() {
    const selectedRadio = this.element.querySelector("input[name='checkout[shipping_method]']:checked");
    const selectedPrice = selectedRadio.closest("[data-shipping-price]").dataset.shippingPrice;
    const isManual = selectedRadio.dataset.shippingMethodIsManualParam === "true";

    const currency = document.querySelector("[data-order-summary-subtotal]").dataset.orderSummaryCurrency;

    const shippings = document.querySelectorAll("[data-order-summary-shipping]");
    const shippingAmount = parseFloat(selectedPrice);

    shippings.forEach((shipping) => {
      shipping.textContent = isManual ? "To be determined" : formatPrice(shippingAmount, currency);
      shipping.dataset.orderSummaryShipping = shippingAmount + "";
    });

    calculateOrderSummery();
  }

  resetServicePointsFrame(shippingMethod) {
    this.servicePointsFrame.innerHTML = `
      <div role="status" class="mt-6">
          <svg aria-hidden="true" class="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.59c0 27.615-22.386 50.001-50 50.001s-50-22.386-50-50 22.386-50 50-50 50 22.386 50 50Zm-90.919 0c0 22.6 18.32 40.92 40.919 40.92 22.599 0 40.919-18.32 40.919-40.92 0-22.598-18.32-40.918-40.919-40.918-22.599 0-40.919 18.32-40.919 40.919Z" fill="currentColor" /><path d="M93.968 39.04c2.425-.636 3.894-3.128 3.04-5.486A50 50 0 0 0 41.735 1.279c-2.474.414-3.922 2.919-3.285 5.344.637 2.426 3.12 3.849 5.6 3.484a40.916 40.916 0 0 1 44.131 25.769c.902 2.34 3.361 3.802 5.787 3.165Z" fill="currentFill" /></svg>
          <span class="sr-only"><%= p_("checkout", "Loading...") %></span>
      </div>
    `;
    if (!!shippingMethod) {
      this.servicePointsFrame.src = `${this.servicePointsFrame.dataset.src}?shipping_method=${shippingMethod}`;
    } else {
      this.servicePointsFrame.src = null;
    }

    this.servicePointsFrame.removeAttribute("complete");
  }
}
