import {Controller} from "@hotwired/stimulus";

// Connects to data-controller="cart"
export default class extends Controller {
  updateItem() {
    this.element.requestSubmit();
  }

  removeItem(event) {
    event.currentTarget.setAttribute("disabled", "true");
    this.element.requestSubmit();
  }
}
