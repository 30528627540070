import {Controller} from "@hotwired/stimulus";

// Connects to data-controller="reveal"
export default class extends Controller {
  static targets = ["item"];

  toggle() {
    this.itemTargets.forEach((item) => {
      item.classList.toggle("hidden");
    });
  }

  show() {
    this.itemTargets.forEach((item) => {
      item.classList.remove("hidden");
    });
  }

  hide() {
    this.itemTargets.forEach((item) => {
      item.classList.add("hidden");
    });
  }
}
