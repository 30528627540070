import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static classes = ["enter"];

  connect() {
    this.element.addEventListener("animationend", () => this.element.classList.remove(this.enterClass), false);
    document.addEventListener("turbo:before-stream-render", () => this.element.classList.add(this.enterClass));
  }
}
