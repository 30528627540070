import {getCookie, setCookie} from "./utils.js";

document.addEventListener("turbo:load", () => {
  if (!document.getElementById("age-modal")) {
    return;
  }

  if (!getCookie("_storefront_age")) {
    document.body.classList.add("modal-visible");
  }

  document.getElementById("confirm-age").onclick = () => {
    setCookie("_storefront_age", "true", {path: "/"});
    document.body.classList.remove("modal-visible");
  };

  document.getElementById("decline-age").onclick = () => {
    document.getElementById("age-modal-front").style.display = "none";
    document.getElementById("age-modal-back").style.display = "block";
  };
});
