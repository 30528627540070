import {Controller} from "@hotwired/stimulus";
import {vimeoParser, youtubeParser} from "../utils";

const YOUTUBE_API = "https://www.youtube.com/iframe_api";
const VIMEO_API = "https://player.vimeo.com/api/player.js";

const ERROR_INVALID_VALUE = 2;
const ERROR_HTML5_VIDEO_PLAYER = 5;
const ERROR_VIDEO_NOT_FOUND = 100;
const ERROR_ALLOWED_ONLY_ON_YOUTUBE = 101;
// The same error as previous one
const ERROR_ALLOWED_ONLY_ON_YOUTUBE_ = 150;
let YTPlayerApi = null;
let YTBeenPlayed = false;

// Connects to data-controller="video-banner"
export default class extends Controller {
  static values = {originUrl: String};

  connect() {
    const body = document.querySelector("body");
    const youtubeVideoId = youtubeParser(this.originUrlValue);
    const vimeoVideoId = vimeoParser(this.originUrlValue);

    switch (true) {
      case !!youtubeVideoId:
        this.loadPlayerApi(YOUTUBE_API);
        this.playYouTubeVideo(youtubeVideoId);
        body.classList.add("youtube-video");
        break;
      case !!vimeoVideoId:
        this.loadPlayerApi(VIMEO_API);
        this.playVimeoVideo(vimeoVideoId);
        break;
      default:
        break;
    }
  }

  loadPlayerApi(api) {
    const scriptSrc = api;

    if (document.querySelector(`script[src='${scriptSrc}']`)) {
      return;
    }

    const tag = document.createElement("script");

    tag.src = scriptSrc;
    const firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }

  onVimeoPlayerAPIReady(loadVimeoPlayerCallback) {
    const isVimeoDefined = setInterval(() => {
      if (window.Vimeo && window.Vimeo.Player) {
        clearInterval(isVimeoDefined);
        loadVimeoPlayerCallback();
      }
    }, 500);
  }

  playYouTubeVideo(id) {
    if (typeof YT === "undefined" || typeof YT.Player === "undefined") {
      window.onYouTubePlayerAPIReady = () => this.loadYouTubePlayer(id);
    } else {
      this.loadYouTubePlayer(id);
    }
  }

  playVimeoVideo(id) {
    if (typeof Vimeo === "undefined" || typeof Vimeo.Player === "undefined") {
      this.onVimeoPlayerAPIReady(() => this.loadVimeoPlayer(id));
    } else {
      this.loadVimeoPlayer(id);
    }
  }

  loadYouTubePlayer(id) {
    YTPlayerApi = new YT.Player("player", {
      height: "390",
      width: "640",
      videoId: id,
      playerVars: {
        playlist: id,
        controls: 0,
        autoplay: 1,
        mute: 1,
        playsinline: 1,
        loop: 1,
        rel: 0,
      },
      events: {
        onReady: (event) => event.target.playVideo(),
        onError: (event) => {
          switch (event.data) {
            case ERROR_HTML5_VIDEO_PLAYER:
              setTimeout(() => {
                event.target.nextVideo();
              }, 3000);
              break;
            case ERROR_INVALID_VALUE:
            case ERROR_VIDEO_NOT_FOUND:
            case ERROR_ALLOWED_ONLY_ON_YOUTUBE:
            case ERROR_ALLOWED_ONLY_ON_YOUTUBE_:
            default:
              break;
          }
        },
        onStateChange: (event) => {
          if (event.data === YT.PlayerState.PLAYING) {
            YTBeenPlayed = true;
          }
          if (event.data !== YT.PlayerState.PLAYING && YTBeenPlayed) {
            YTPlayerApi.seekTo(0);
            YTPlayerApi.playVideo();
          }
        },
      },
    });
  }

  loadVimeoPlayer(id) {
    const options = {
      id: id,
      background: true,
      muted: true,
      autoplay: true,
      loop: true,
    };

    YTPlayerApi = new Vimeo.Player("player", options);
  }
}
