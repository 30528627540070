import {Controller} from "@hotwired/stimulus";
import {setCookie} from "../utils.js";

export default class extends Controller {
  close() {
    setCookie("_storefront_country", "true", {path: "/"});
    this.element.classList.add("hidden");
  }

  choose(event) {
    setCookie("_storefront_country", event.params.country || "true", {path: "/"});
  }
}
