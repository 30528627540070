export function getCookie(name) {
  var v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return v ? v[2] : null;
}

export function setCookie(key, value, attributes) {
  if (typeof attributes.expires === "number") {
    var expires = new Date();
    expires.setMilliseconds(expires.getMilliseconds() + attributes.expires * 864e5);
    attributes.expires = expires;
  }

  attributes.expires = attributes.expires ? attributes.expires.toUTCString() : "";

  try {
    result = JSON.stringify(value);
    if (/^[\{\[]/.test(result)) {
      value = result;
    }
  } catch (e) {}

  value = encodeURIComponent(String(value)).replace(
    /%(23|24|26|2B|3A|3C|3E|3D|2F|3F|40|5B|5D|5E|60|7B|7D|7C)/g,
    decodeURIComponent,
  );

  key = encodeURIComponent(String(key));
  key = key.replace(/%(23|24|26|2B|5E|60|7C)/g, decodeURIComponent);
  key = key.replace(/[\(\)]/g, escape);

  var stringifiedAttributes = "";

  for (var attributeName in attributes) {
    if (!attributes[attributeName]) {
      continue;
    }
    stringifiedAttributes += "; " + attributeName;
    if (attributes[attributeName] === true) {
      continue;
    }
    stringifiedAttributes += "=" + attributes[attributeName];
  }
  return (document.cookie = key + "=" + value + stringifiedAttributes);
}

export function calculateOrderSummery() {
  const subtotal = document.querySelector("[data-order-summary-subtotal]");
  const shipping = document.querySelector("[data-order-summary-shipping]");
  const discount = document.querySelector("[data-order-summary-discount]");
  const vatRate = document.querySelector("[data-order-summary-vat-rate]");

  // update elements
  const vats = document.querySelectorAll("[data-order-summary-vat-rate]");
  const totals = document.querySelectorAll("[data-order-summary-total]");

  const shippingAmount = parseFloat(shipping.dataset.orderSummaryShipping);
  const subtotalAmount = parseFloat(subtotal.dataset.orderSummarySubtotal);
  const discountAmount = discount ? parseFloat(discount.dataset.orderSummaryDiscount) : 0;
  const vatRateAmount = vatRate ? parseFloat(vatRate.dataset.orderSummaryVatRate) / 100 : 0;

  const currency = subtotal.dataset.orderSummaryCurrency;
  const newTotal = subtotalAmount + shippingAmount - discountAmount;

  if (vatRateAmount > 0) {
    const newTotalWithVat = newTotal * (1 + vatRateAmount);
    const newVatAmount = newTotalWithVat - newTotal;

    vats.forEach((vat) => (vat.textContent = formatPrice(newVatAmount, currency)));
    totals.forEach((total) => (total.textContent = formatPrice(newTotalWithVat, currency)));
  } else {
    totals.forEach((total) => (total.textContent = formatPrice(newTotal, currency)));
  }
}

export function formatPrice(amount, currency) {
  return amount.toLocaleString("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: "currency",
    currencyDisplay: "code",
    currency: currency,
  });
}

// taken from https://stackoverflow.com/questions/3452546/how-do-i-get-the-youtube-video-id-from-a-url
export function youtubeParser(url) {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
}

//taken from https://blog.devgenius.io/how-to-extract-the-id-of-a-youtube-or-vimeo-url-with-javascript-ad5e2d1049a
export function vimeoParser(url) {
  // Look for a string with 'vimeo', then whatever, then a
  // forward slash and a group of digits.
  const match = /vimeo.*\/(\d+)/i.exec(url);
  // If the match isn't null (i.e. it matched)
  if (match) {
    // The grouped/matched digits from the regex
    return match[1];
  }
}
