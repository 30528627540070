import {Controller} from "@hotwired/stimulus";
import Swiper from "swiper";
import {Navigation} from "swiper/modules";

export default class extends Controller {
  static values = {
    type: String,
    slidesPerView: Number,
    currentSlideSlug: String,
  };

  connect() {
    switch (this.typeValue) {
      case "product":
        this.initProductSlider();
        break;
      case "collections":
        if (window.innerWidth > 767) {
          this.initCollectionsSlider();
        }
        break;
      default:
        this.initProductSlider();
    }
  }

  initProductSlider() {
    new Swiper(this.element, {
      slidesPerView: this.slidesPerViewValue || "auto",
      spaceBetween: 3,
    });
  }

  initCollectionsSlider() {
    const slides = Array.from(this.element.querySelectorAll(".swiper-slide"));
    const matchingSlideIndex = slides.findIndex((slide) => {
      return slide.getAttribute("data-slug") === this.currentSlideSlugValue;
    });
    const initialSlideIndex = matchingSlideIndex >= 0 ? matchingSlideIndex : 0;

    new Swiper(this.element, {
      modules: [Navigation],
      slidesPerView: "auto",
      spaceBetween: 5,
      slidesOffsetBefore: 20,
      slidesOffsetAfter: 15,
      watchOverflow: true,
      mousewheel: true,
      keyboard: true,
      initialSlide: initialSlideIndex,
      navigation: {
        nextEl: ".swiper-btn-next",
        prevEl: ".swiper-btn-prev",
      },
      on: {
        init: (swiper) => {
          this.updateNavigation(swiper);

          if (swiper.isBeginning && swiper.isEnd) {
            this.hideNavigation(swiper);
          } else {
            swiper.wrapperEl.classList.remove("justify-center");
          }
        },
        resize: (swiper) => {
          if (swiper.isBeginning && swiper.isEnd) {
            this.hideNavigation(swiper);
          } else {
            swiper.wrapperEl.classList.remove("justify-center");
            swiper.navigation.nextEl.classList.remove("!hidden");
          }
        },
        slideChange: (swiper) => {
          this.updateNavigation(swiper);
        },
      },
    });
  }

  updateNavigation(swiper) {
    const toggleButton = (button, shouldHide) => {
      if (button) button.classList.toggle("!hidden", shouldHide);
    };

    toggleButton(swiper.navigation.nextEl, swiper.isEnd);
    toggleButton(swiper.navigation.prevEl, swiper.isBeginning);
  }

  hideNavigation(swiper) {
    swiper.navigation.prevEl.classList.add("!hidden");
    swiper.navigation.nextEl.classList.add("!hidden");
  }
}
