import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.remove(6000);
  }

  close(e) {
    this.element.classList.add("fade-out");
    this.remove(500);
  }

  remove(time) {
    setTimeout(() => this.element.remove(), time);
  }
}
