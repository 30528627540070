import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "container",
    "checkboxAddNew",
    "name",
    "country",
    "company",
    "line1",
    "line2",
    "postcode",
    "city",
    "phoneNumber",
    "phoneCountry",
  ];

  formData = {};

  connect() {
    const showContainer = this.checkboxAddNewTarget.checked || !this.validateRequiredFields();
    this.toggleContainer(showContainer);

    const addressBookData = this.checkboxAddNewTarget.getAttribute("data-address-book-selected-param");
    if (addressBookData && addressBookData !== "false") {
      try {
        this.formData = JSON.parse(addressBookData);
      } catch (error) {
        console.error("Failed to parse address book data:", error);
      }
    }
  }

  onSelect(event) {
    const {selected: addressData} = event.params || {};

    if (!addressData) {
      console.error("Invalid or missing address data");
      return;
    }

    this.updateFields(addressData);

    this.toggleContainer(!this.validateRequiredFields());
  }

  onChange(event) {
    const fieldName = event.target.getAttribute("data-address-book-target");
    if (!fieldName) return;

    this.formData[fieldName] = event.target.value;
  }

  addNew() {
    this.updateFields(Object.keys(this.formData).length ? this.formData : {});
    this.toggleContainer(true);
  }

  updateFields(addressData = {}) {
    const {
      name = "",
      country = "",
      company = "",
      line1 = "",
      line2 = "",
      postcode = "",
      city = "",
      phoneNumber = "",
      phoneCountry = "",
    } = addressData;

    this.nameTarget.value = name;
    this.companyTarget.value = company;
    this.line1Target.value = line1;
    this.line2Target.value = line2;
    this.postcodeTarget.value = postcode;
    this.cityTarget.value = city;

    // Update country only if a value is provided
    if (country) {
      this.countryTarget.value = country;
    }

    // Update phoneNumber and phoneCountry if targets exist and values are provided
    if (this.hasPhoneNumberTarget) {
      this.phoneNumberTarget.value = phoneNumber;
    }
    if (this.hasPhoneCountryTarget && phoneCountry) {
      this.phoneCountryTarget.value = phoneCountry;
    }
  }

  validateRequiredFields() {
    let isValid = true;

    this.constructor.targets.forEach((targetName) => {
      if (this.targets.has(targetName)) {
        const targetElement = this.targets.find(targetName);

        // Check if the target has the "data-required" attribute
        if (targetElement.matches('[data-required="true"]')) {
          const value = targetElement.value.trim();

          // If any required field is empty, set the flag to false
          if (!value) {
            isValid = false;
          }
        }
      }
    });

    return isValid;
  }

  toggleContainer(show) {
    if (show) {
      this.containerTarget.classList.remove("hidden");
    } else {
      this.containerTarget.classList.add("hidden");
    }
  }
}
