import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["shipment", "clickAndCollect"];

  static values = {
    selected: String,
  };

  connect() {
    this.toggle(this.selectedValue);
  }

  selected(event) {
    this.toggle(event.params.selected);
  }

  toggle(deliveryMethod) {
    if (deliveryMethod == "SHIPMENT") {
      this.shipmentTarget.classList.remove("hidden");
      this.clickAndCollectTarget.classList.add("hidden");
    } else if (deliveryMethod == "CLICK_AND_COLLECT") {
      this.shipmentTarget.classList.add("hidden");
      this.clickAndCollectTarget.classList.remove("hidden");
    } else if (deliveryMethod == "DIGITAL") {
      this.shipmentTarget.classList.add("hidden");
      this.clickAndCollectTarget.classList.add("hidden");
    }
  }
}
