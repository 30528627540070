import {Controller} from "@hotwired/stimulus";
import {calculateOrderSummery} from "../utils.js";

export default class extends Controller {
  initialize() {
    this.handleEvent = this.beforeSteamRenderHandle.bind(this);
  }

  connect() {
    document.addEventListener("turbo:before-stream-render", this.handleEvent);
  }

  disconnect() {
    document.removeEventListener("turbo:before-stream-render", this.handleEvent);
  }

  beforeSteamRenderHandle(event) {
    const discountFrameTags = ["discount_form_mobile", "discount_line_mobile", "discount_form", "discount_line"];

    if (discountFrameTags.includes(event.target.target)) {
      event.target.performAction();
    }

    if (discountFrameTags[discountFrameTags.length - 1] === event.target.target) {
      calculateOrderSummery();
    }
  }
}
