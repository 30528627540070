import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static values = {id: String};

  open() {
    const body = document.querySelector("body");
    const modal = document.getElementById(this.idValue);

    body.setAttribute("data-modal-id", this.idValue);

    body.classList.add("overflow-hidden");
    modal.classList.add("modal-active");
  }

  close() {
    const body = document.querySelector("body");
    const modalId = body.getAttribute("data-modal-id");
    const modal = document.getElementById(modalId);

    body.removeAttribute("data-modal-id");

    body.classList.remove("overflow-hidden");
    modal.classList.remove("modal-active");
  }

  closeWithKeyboard(e) {
    if (e.keyCode !== 27) return;

    this.close();
  }
}
