import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.toggle();
  }

  toggle() {
    const elements = this.element.querySelectorAll("input");

    for (const element of elements) {
      const label = element.closest("[data-css-checked]");
      const text = label.querySelector("[data-css-checked]");

      this.toggleClasses(label, element.checked);
      this.toggleClasses(text, element.checked);
    }
  }

  toggleClasses(element, isChecked) {
    const checkedClasses = element.dataset.cssChecked.split(" ");
    const uncheckedClasses = element.dataset.cssUnchecked.split(" ");

    if (isChecked) {
      element.classList.add(...checkedClasses);
      element.classList.remove(...uncheckedClasses);
    } else {
      element.classList.add(...uncheckedClasses);
      element.classList.remove(...checkedClasses);
    }
  }
}
