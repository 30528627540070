import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.timeoutId = null;
  }

  clear() {
    this.inputTarget.value = "";
  }

  focus() {
    document.getElementById("search-input").focus();
  }

  delaySubmit() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    if (this.inputTarget.value.length >= 3) {
      this.timeoutId = setTimeout(() => this.submit(), 300);
    }
  }

  submit() {
    this.element.requestSubmit();
  }
}
